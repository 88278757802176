import cn from 'classnames'

import IconLoader from 'images/icon-loader-white.svg'

import { ButtonHTMLAttributes, PropsWithChildren, useMemo } from 'react'

import styles from './styles.module.scss'

export default function ButtonOrange({
  className = '',
  isLoading = false,
  disabled = false,
  ...props
}: PropsWithChildren<
  {
    isLoading?: boolean
    className?: string
  } & ButtonHTMLAttributes<HTMLButtonElement>
>) {
  const compiledClassName = useMemo(
    () => (disabled ? cn(styles.button, styles.disabled, className) : cn(styles.button, className)),
    [disabled, className],
  )
  return (
    <button className={compiledClassName} type="button" disabled={disabled} {...props}>
      {isLoading ? <img src={IconLoader} alt="Loading" width={25} height={25} /> : props.children}
    </button>
  )
}

import { FC, ReactElement } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

export interface ISlideParams {
  id: string | number
  link: string
  author: {
    avatar?: string
    avatarAlt?: string
    name: string
    position?: string
  }
  image: string
  imageAlt: string
  imageWidth: number
  title: string | ReactElement
  text: string | ReactElement
}

export const Slide: FC<ISlideParams> = (props) => {
  return (
    <div className={styles.slide}>
      <a href={props.link} target="_blank" rel="noopener nofollow noreferrer">
        <div className={styles.answer}>
          {props.author.avatar && (
            <img
              src={props.author.avatar}
              className={styles.avatar}
              alt={props.author.avatarAlt}
              loading="lazy"
              width={60}
              height={60}
            />
          )}
          <div className={styles.author}>
            <div className={styles.name}>{props.author.name}</div>
            {props.author.position && <div className={styles.position}>{props.author.position}</div>}
          </div>
          <img
            src={props.image}
            className={cn(styles.firm, styles.desktop)}
            alt={props.imageAlt}
            height={56}
            width={props.imageWidth}
            loading="lazy"
          />
        </div>
        <header className={styles.header}>{props.title}</header>
        <div className={styles.body}>{props.text}</div>
        <img
          src={props.image}
          className={cn(styles.firm, styles.mobile)}
          alt={props.imageAlt}
          height={56}
          width={props.imageWidth}
          loading="lazy"
        />
      </a>
    </div>
  )
}

import { FC, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { debounce } from 'utils/debounce'

import dotCage from './assets/dot-cage.svg'
import { ISlideParams, Slide } from './components/slide'

import styles from './styles.module.scss'

export const SwiperBlock: FC<{
  title: string
  slides: ISlideParams[]
}> = ({ title, slides }) => {
  const [dotPosition, setDotPosition] = useState(0)

  const startX = useRef(-1)
  const startY = useRef(-1)

  const dots = []
  const dotsCages = []

  const slideTo = (i: number): (() => void) => () => {
    setDotPosition(i)
  }

  for (let i = 0; i < slides.length; i++) {
    dots.push(<div key={i} className={cn(styles.dot, styles.dotEmpty)} />)
    dotsCages.push(
      <img key={i} src={dotCage} height={20} width={40} onClick={slideTo(i)} alt={`dot-cage-${i}`} loading="lazy" />,
    )
  }

  const prevSlide = (): void => {
    setDotPosition((i) => {
      const newI = i - 1
      return newI >= 0 ? newI : slides.length - 1
    })
  }

  const nextSlide = (): void => {
    setDotPosition((i) => (i + 1) % slides.length)
  }

  const handleEnd = (): void => {
    startX.current = -1
    startY.current = -1
  }

  const margin = 10
  const moveUpdater = debounce((x, y) => {
    const diffY = startY.current - y
    if (Math.abs(diffY) > margin) {
      handleEnd()
      return
    }

    const diffX = startX.current - x
    if (diffX > margin) {
      nextSlide()
      handleEnd()
    } else if (diffX < -margin) {
      prevSlide()
      handleEnd()
    }
  }, 16.6)

  const handleTouchStart = (e: TouchEvent) => {
    startX.current = e.targetTouches[0].clientX
    startY.current = e.targetTouches[0].clientY
  }

  const handleTouchMove = (e: TouchEvent) => {
    const x = e.targetTouches[0].clientX
    if (startX.current > 0 && Math.abs(startX.current - x) > margin) {
      e.preventDefault()
      moveUpdater(x, e.targetTouches[0].clientY)
    }
  }

  const slidesRef = useRef<HTMLDivElement>()

  useEffect(() => {
    slidesRef.current.addEventListener('touchstart', handleTouchStart, { passive: true })
    slidesRef.current.addEventListener('touchmove', handleTouchMove, { passive: true })
    slidesRef.current.addEventListener('touchend', handleEnd, { passive: true })
    slidesRef.current.addEventListener('touchcancel', handleEnd, { passive: true })

    return () => {
      if (slidesRef.current) {
        slidesRef.current.removeEventListener('touchstart', handleTouchStart)
        slidesRef.current.removeEventListener('touchmove', handleTouchMove)
        slidesRef.current.removeEventListener('touchend', handleEnd)
        slidesRef.current.removeEventListener('touchcancel', handleEnd)
      }
    }
  }, [])

  return (
    <>
      <div className={styles.swiperWrapper}>
        <div className={styles.swiperButton} onClick={prevSlide}>
          <div />
        </div>
        <div className={styles.swiperContent}>
          <h2 className={styles.title}>{title}</h2>
          <div
            className={styles.slides}
            style={{ left: `calc(-${dotPosition * 100}% - ${dotPosition * 10}px)` }}
            ref={slidesRef}
          >
            {slides.map((item) => (
              <Slide key={item.id} {...item} />
            ))}
          </div>
          <div className={styles.dotsWrapper}>
            <div className={styles.dotsWrapperInner}>
              <div className={cn(styles.dot, styles.dotActive)} style={{ left: dotPosition * 40 + 'px' }} />
              {dots}
              <div className={styles.dotsCage}>{dotsCages}</div>
            </div>
          </div>
        </div>
        <div className={cn(styles.swiperButton, styles.swiperButtonNext)} onClick={nextSlide}>
          <div />
        </div>
      </div>
    </>
  )
}
export default SwiperBlock

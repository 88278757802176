export interface IQuestion {
  title: string
  answer: string
}

export const defaultQuestions: IQuestion[] = [
  {
    title: 'How does a crypto loan work?',
    answer: `
        <ol>
          <li><b>You choose the conditions</b>: the crypto coin as collateral, the coin in which you want to get your crypto backed loan and LTV.</li>
          <li><b>You specify the address</b> you want to get your crypto currency loan at and deposit the collateral.</li>
          <li><b>You can close the loan whenever you want</b> to return the amount of credit and interest, and then get back your collateral.</li>
        </ol>
      `,
  },
  {
    title: 'What is your APR and how is it calculated?',
    answer: `
        <p>
          The annual percentage rate depends on the selected <b>crypto loan currencies and ranges from 12% to 17%</b>. 
          The crypto interest is calculated monthly from the time the crypto currency loan was taken and is included in the repayment amount.
        </p>
      `,
  },
  {
    title: 'How quickly can I get a crypto loan? How quickly will you refund me the collateral?',
    answer: `
        <p>
          The average <b>period of giving crypto loans is 15 minutes</b>, depending on how fast we receive your loan collateral.
          And the average <b>period for returning your collateral is 1 hour</b>, as we store the majority of funds 
          in our system of cold wallets.
        </p>
      `,
  },
  {
    title: 'What if the collateral currency rate changes after I get a crypto loan?',
    answer: `
        <p>
          The collateral currency rate doesn’t affect the repayment amount of the loan. <b>When you repay the crypto
          loan, we will return you the same amount of cryptocurrency as deposited.</b>
        </p>
        <p>
          If your collateral size is 1 BTC, you will get back 1 BTC irrespective of its current market price. This is
          the fundamental value we provide: you can get some money now as a loan while your main long-term investment in
          crypto stays with you and keeps bringing you profits.
        </p>
        <p>
          Although, if the rate of the crypto collateral currency reaches the liquidation level, the collateral will be
          automatically liquidated and the crypto currency loan will be closed. But don’t worry, we will notify you
          multiple times when the current rate approaches the margin call.
        </p>
      `,
  },
  {
    title: 'What is a margin call?',
    answer: `
        <p>
          The margin call, or liquidation price, is <b>the collateral’s asset value at which we have to liquidate your
          loan</b>. This amount is calculated due to us being able to compensate the issued loan if the collateral’s
          value drops in price significantly.
        </p>
      `,
  },
  {
    title: 'What is LTV?',
    answer: `
        <p>
          The loan-to-value ratio is the difference between the loan amount and the current market value of your
           collateral. Right now, our LTV is set at 50%, 60%, 70% and 80% – that means that <b>we give you 50%, 60%,
            70% and 80% of the collateral value as a loan</b>. This diversity of options allows us to minimize your
             risks and protect you from stark volatility of your collateral price.
        </p>
      `,
  },
  {
    title: 'Can I close my loan at any time?',
    answer: `
        <p>
          Yes, there are no restrictions. You can close your loan in a month, a year, or even tomorrow and we’ll return
          your collateral with no questions.
        </p>
      `,
  },
  {
    title: 'What happens if you don’t repay your cryptocurrency loan?',
    answer: `
      <p>
        If you are unable to repay the loan, we have to liquidate it. This means that your collateral will be sold to
        cover costs. At the same time, the received loan remains fully at your disposal and you don’t have to pay your loan debt any more.
      </p>`,
  },
  {
    title: 'Can I have multiple loans?',
    answer: `
        <p>
          Sure, you can have multiple loans with different or the same crypto asset as collateral. You will track them as separate
           loans. When it comes to the repayment, interest and fees are charged for each loan separately.
        </p>
      `,
  },
  {
    title: 'How safe is CoinRabbit?',
    answer: `
        <p>
          The service was launched at the end of 2020. Since the first day we partner with such proven by time companies
          like ChangeNOW, Guarda Wallet, Atomic Wallet, Cake Wallet. We do not invest in third-party projects, because
          <b>our clients' security is the main priority for us</b>. Our business model is stable and transparent.
        </p>
        <p>
          We do not offer yields above the market, because additional yields are always associated with higher risks and
          clients need to keep this in mind while choosing a financial service. If you need to make sure the service is
          safe and easy to use, you can always try it with a small collateral.
        </p>
      `,
  },
]
